<template>
  <div class="app-main-content">
    <card>
      <template #tools>
        <div class="title">任务统计报表</div>
        <div class="right">
          <div class="item">
            <span class="label">项目成员</span>
            <a-select style="width: 250px;height: 32px;overflow: hidden;" allowClear :maxTagCount="2" mode="multiple"
                      show-search class="sort-select" placeholder="请选择" optionFilterProp="children"
                      v-model="selectOwners" @change="selectOwnersChange">
              <a-select-option :value="item.oid" v-for="item in ownersList" :key="item.oid">{{
                  item.title ? item.name + '-' + item.title : item.name
                }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input class="search-input" v-model="queryParams.project_name" placeholder="请输入项目名称">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-button class="search" @click="getIssueList">查询</a-button>
          </div>
          <div class="item">
            <a-button class="export" @click="exportReportForm">导出报表</a-button>
          </div>
        </div>
      </template>
      <div class="main">
        <div class="table">
          <a-table
              v-if="list.length > 0"
              :data-source="list"
              rowKey="id"
              bordered
              :expandIcon="customExpandIcon"
              defaultExpandAllRows
              :pagination="false"
          >
            <a-table-column title="项目名称">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  {{ row.project_name }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="负责人" data-index="project_admin_name" width="234px">
              <template v-slot:default="_, row">
                <div class="edp-owners">
                  <div>
                    <a-avatar :size="16" :src="row.issue_owner_pic" />
                    <span>{{ row.issue_owner_title ? row.issue_owner_name + '-' + row.issue_owner_title : row.issue_owner_name }}</span>
                    <div :class="{
                        tag: true,
                        admin: !!row.children
                    }">{{ row.children ? '负责人' : '成员' }}
                    </div>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务总数" data-index="issue_total_count" width="191px"></a-table-column>
            <a-table-column title="未完成" data-index="issue_nostart_count" width="174px"></a-table-column>
            <a-table-column title="进行中" data-index="issue_running_count" width="174px"></a-table-column>
            <a-table-column title="已完成" data-index="issue_finish_count" width="174px"></a-table-column>
            <a-table-column title="延误" data-index="issue_delay_count" width="156px"></a-table-column>
            <a-table-column title="完成度" data-index="complete_percent" width="174px">
              <template v-slot:default="_, row">
                {{ row.complete_percent }}%
              </template>
            </a-table-column>
            <a-table-column title="准时率" data-index="all_ontime_rate" width="114px">
              <template v-slot:default="_, row">
                {{ row.ontime_rate }}%
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { h } from "vue";
import { fetch } from "@/utils/request";
import { genGuid } from "@/utils/helpFunc"

export default {
  data() {
    return {
      queryParams: {
        project_name: '',
        issue_owners: ''
      },
      selectOwners: [],
      ownersList: [],
      list: []
    }
  },
  created() {
    this.getOwnersList()
    this.getIssueList()
  },
  methods: {
    customExpandIcon(props) {
      // 是否有子项，无子项不展示图标
      // record是每行的数据，后端返回的数据会有一个hasChild字段判断是project/list否有子项
      if (props.record && Array.isArray(props.record.children)) {
        // props.expanded a-table组件判断是否展开了为true是展开,false为没展开
        if (props.expanded) {
          // onClick事件必须添加上，相当于把a-table里的展开事件给了自定义的图标，不加的话点击会失效
          return h("i", {
            class: "iconfont icon-xiangxia expand-icon",
            on: { click: e => props.onExpand(props.record, e) }
          })
        } else {
          return h("i", {
            class: "iconfont icon-xiangxia expand-icon open",
            on: { click: e => props.onExpand(props.record, e) }
          })
        }
      } else {
        return h("span", { style: 'padding-left: 7px' })
      }
    },
    // 获取负责人数据
    async getOwnersList() {
      const res = await fetch("post", "/cpyaccount/retrieve", { page_size: 999 })
      if (res.status === 0) {
        this.ownersList = res.data.datas
      }
    },
    selectOwnersChange(e) {
      this.queryParams.issue_owners = e.join(',')

      this.getIssueList()
    },
    async getIssueList() {
      this.list = []
      const res = await fetch("post", "/sta/issue", this.queryParams)
      if (res.status === 0) {
        console.log(res);
        this.list = res.data.map(e => {
          return {
            ...e,
            complete_percent: e.all_complete_percent,
            issue_delay_count: e.all_issue_delay_count,
            issue_finish_count: e.all_issue_finish_count,
            issue_nostart_count: e.all_issue_nostart_count,
            issue_running_count: e.all_issue_running_count,
            issue_total_count: e.all_issue_total_count,
            ontime_rate: e.all_ontime_rate,
            issue_owner_pic: e.pic,
            issue_owner_name: e.project_admin_name,
            issue_owner_title:e.project_admin_title,
            id: genGuid(32),
            children: e.children.map(e1 => {
              return {
                ...e1,
                project_name: e.project_name,
                id: genGuid(32)
              }
            })
          }
        })
      }
    },
    exportReportForm() {
      window.open(this.$toUrl(`/sta/export/issue?project_name=${this.queryParams.project_name}&owners=${this.queryParams.issue_owners}`, true, "api"))
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  /deep/ .edp-card {
    .edp-card-head {
      display: none;
    }

    .edp-card-tools {
      padding: 30px;
      min-height: 32px;

      .title {
        font-weight: 500;
        font-size: 22px;
        color: #4D4D4D;
      }
    }

    .edp-card-body {
      .main {
        padding: 0 30px;

        .table {
          .ant-table {
            .ant-table-thead {
              > tr {
                > th {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 18px;
                  height: 18px;
                  color: #4D4D4D;
                  padding: 16px;
                  border-color: transparent;
                  background: #f8f8f8;

                  &:nth-last-child(1) {
                    border-color: #EDEDED;
                  }
                }
              }
            }

            .ant-table-tbody {
              tr {
                td {
                  font-weight: 400;
                  height: 16px;
                  line-height: 16px;
                  font-size: 16px;
                  color: #4D4D4D;
                  padding: 16px;
                  border-right-color: transparent;

                  .edp-title {
                    font-size: 16px;
                  }

                  .edp-owners {
                    .tag {
                      display: inline-block;
                      width: 46px;
                      height: 18px;
                      border-radius: 4px 4px 4px 4px;
                      background: #ffffff;
                      color: #0889FF;
                      border: 1px solid #0889FF;
                      font-weight: 400;
                      font-size: 12px;
                      text-align: center;
                      vertical-align: middle;

                      &.admin {
                        color: #F0A100;
                        border-color: #F0A100;
                      }
                    }
                  }

                  &:nth-last-child(1) {
                    border-right-color: #EDEDED;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
